import { ListenerFn } from 'eventemitter2';
import { useCallback, useEffect, useMemo } from 'react';

import { globalState } from '../models';
import { portalGate as portalGateToContentFrame } from '../portalGates/contentFrame/to/reactNativeWebview';
import { ContentFrameEventMessageArgumentMap } from '../types/events';
import { useDeepEqualMemo } from './useDeepEqualMemo';


export const useCommonContentFrameListenerOptions = () => {
  const currentAppState = globalState(useCallback((state) => state.mobileAppState, []));

  const commonContentFrameListenerOptions = useMemo(() => {
    return {
      enabled: currentAppState === 'active',
    };
  }, [currentAppState]);
  return commonContentFrameListenerOptions;
};
export function useContentFrameEventListener<
  T extends Parameters<typeof portalGateToContentFrame.on>[0],
>(
  eventName: T,
  callback: (args: ContentFrameEventMessageArgumentMap[T]) => void,
  optionsParam: { enabled: boolean; },
) {
  const options = useDeepEqualMemo(optionsParam);

  useEffect(() => {
    const contentFrameEventListeners: { [event: string]: ListenerFn; } = {};

    const on: typeof portalGateToContentFrame.on = (message, callback) => {
      const result = portalGateToContentFrame.on(message, callback);
      contentFrameEventListeners[message] = callback;
      return result;
    };

    if (!options.enabled) {
      return;
    }

    on(eventName, callback);

    return () => {
      for (const [event, listener] of Object.entries(contentFrameEventListeners)) {
        portalGateToContentFrame.off(event as keyof ContentFrameEventMessageArgumentMap, listener);
      }
    };
  }, [options, callback, eventName]);
}
