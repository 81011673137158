import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFindInDocument } from 'shared/foreground/useFindInDocument';
import { ShortcutId } from 'shared/types/keyboardShortcuts';

import { useKeyboardShortcutPreventDefault } from '../../hooks/useKeyboardShortcut';
import { useShortcutsMap } from '../../utils/shortcuts';
import documentContentStyles from '../DocumentTextContent.module.css';
import ChevronDownIcon from '../icons/ChevronDownIcon';
import ChevronUpIcon from '../icons/ChevronUpIcon';
import SearchNavIcon from '../icons/SearchNavIcon';
import StrokeCancelIcon from '../icons/StrokeCancelIcon';
import Spinner from '../Spinner';
import styles from './FindInDocumentBar.module.css';

export function FindInDocumentBar() {
  const [isVisible, setVisible] = useState(false);
  const {
    inputMatchCount,
    clearSearch,
    goToNextOccurrence,
    goToPrevOccurrence,
    searchInProgress,
    searchText,
    onChangeText,
  } = useFindInDocument(isVisible);


  const inputRef = useRef<HTMLInputElement>(null);
  const shortcutsMap = useShortcutsMap();

  const closeFindInDocumentBar = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    clearSearch();
    setVisible(false);
  }, [clearSearch]);

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.FindInDocument],
    useCallback(() => {
      setVisible((isVisible) => !isVisible);
    }, []),
    {
      description: 'Find in Document',
      shouldBeIgnoredInFormTags: false,
    },
  );

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.Esc],
    useCallback(() => {
      closeFindInDocumentBar();
    }, [closeFindInDocumentBar]),
    { description: 'Find in Document' },
  );

  useEffect(() => {
    if (isVisible) {
      inputRef.current?.focus();
      return;
    }

    inputRef.current?.blur();
  }, [isVisible]);

  const handleClearSearch = useCallback(() => {
    clearSearch();
    inputRef.current?.focus();
  }, [clearSearch]);


  const handleInputKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Escape') {
        closeFindInDocumentBar();
      }
      if (event.key === 'Enter') {
        if (event.shiftKey) {
          goToPrevOccurrence();
        } else {
          goToNextOccurrence();
        }
      }
    },
    [closeFindInDocumentBar, goToNextOccurrence, goToPrevOccurrence],
  );

  return (
    <div
      className={`${documentContentStyles.documentSearchBarWrapper} ${isVisible ? styles.visible : styles.hidden}`}
    >
      <div className={documentContentStyles.searchInputWrapper}>
        <div className={documentContentStyles.searchIcon}>
          <SearchNavIcon />
        </div>
        <input
          value={searchText}
          ref={inputRef}
          aria-labelledby="document-search-label"
          autoComplete="off"
          autoCorrect="off"
          className={documentContentStyles.searchInput}
          placeholder="Find in document..."
          onKeyDown={handleInputKeyDown}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onChangeText(event.target.value)
          }
        />
        <div className={documentContentStyles.searchInputInfoContainer}>
          {searchInProgress
            ? <Spinner className={styles.searchSpinner} />
            : inputMatchCount
          }
          <button
            type="button"
            aria-label="Clear search"
            className={documentContentStyles.searchButton}
            onClick={handleClearSearch}
          >
            <StrokeCancelIcon text="Clear" className={documentContentStyles.searchButtonIcon} />
          </button>
        </div>
      </div>
      <div className={documentContentStyles.searchNavActions}>
        <button
          type="button"
          onClick={goToPrevOccurrence}
          aria-label="Previous result"
          className={documentContentStyles.searchButton}
        >
          <ChevronUpIcon text="Previous result" className={documentContentStyles.searchButtonIcon} />
        </button>
        <button
          type="button"
          onClick={goToNextOccurrence}
          aria-label="Next result"
          className={documentContentStyles.searchButton}
        >
          <ChevronDownIcon text="Next result" className={documentContentStyles.searchButtonIcon} />
        </button>
      </div>
      <div className={documentContentStyles.searchDoneAction}>
        <button
          type="button"
          onClick={closeFindInDocumentBar}
          className={documentContentStyles.searchDoneButton}
        >
          <span>Done</span>
        </button>
      </div>
    </div>
  );
}
