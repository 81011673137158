import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  portalGate as portalGateToContentFrame,
} from './portalGates/contentFrame/to/singleProcess';
import {
  useCommonContentFrameListenerOptions,
  useContentFrameEventListener,
} from './utils/useContentFrameEventListener';


export function useFindInDocument(isVisible: boolean) {
  const [query, onChangeQuery] = useState('');
  const [occurrenceCount, setOccurrenceCount] = useState(0);
  const [currentOccurrenceIndex, setCurrentOccurrenceIndex] = useState(0);
  const [searchInProgress, setSearchInProgress] = useState(false);
  const [isExhaustive, setIsExhaustive] = useState(false);

  const exhaustivePlus = isExhaustive ? '' : '+';

  const matchesText =
    occurrenceCount > 0 ? `${currentOccurrenceIndex + 1} of ${occurrenceCount}${exhaustivePlus}` : 'No matches';

  const inputMatchCount =
    query === '' || query === undefined
      ? ''
      : matchesText;

  const clearSearch = useCallback(() => {
    portalGateToContentFrame.methods.findInDocumentReset();

    onChangeQuery('');
    setOccurrenceCount(0);
    setCurrentOccurrenceIndex(0);
  }, []);

  const findInDocument = useCallback(() => {
    if (query === '') {
      return;
    }

    portalGateToContentFrame.methods.findInDocumentInit(query);
  }, [query]);

  useEffect(() => {
    if (occurrenceCount === 0) {
      return;
    }

    portalGateToContentFrame.methods.findInDocumentGoToOccurrence(currentOccurrenceIndex);
  }, [currentOccurrenceIndex, occurrenceCount]);

  const goToNextOccurrence = useCallback(() => {
    if (occurrenceCount === 0) {
      return;
    }

    setCurrentOccurrenceIndex((index) => (index + 1) % occurrenceCount);
  }, [occurrenceCount]);

  const goToPrevOccurrence = useCallback(() => {
    if (occurrenceCount === 0) {
      return;
    }

    setCurrentOccurrenceIndex((index) => (index - 1 + occurrenceCount) % occurrenceCount);
  }, [occurrenceCount]);

  const debouncedFindInDocument = useMemo(() => debounce(findInDocument, 300), [findInDocument]);

  useEffect(() => {
    if (!isVisible || query === '') {
      return;
    }

     // Reset occurrence state before searching
    setCurrentOccurrenceIndex(0);
    setOccurrenceCount(0);

    debouncedFindInDocument();

    return () => debouncedFindInDocument.cancel();
  }, [query, isVisible, debouncedFindInDocument]);

  const options = useCommonContentFrameListenerOptions();

  useContentFrameEventListener(
    'find-in-document-started',
    useCallback(() => setSearchInProgress(true), []),
    options,
  );

  useContentFrameEventListener(
    'find-in-document-finished',
    useCallback(() => setSearchInProgress(false), []),
    options,
  );

  useContentFrameEventListener(
    'find-in-document-occurrence-count-updated',
    useCallback(({ count, isExhaustive }) => {
      setOccurrenceCount(count);
      setIsExhaustive(isExhaustive);
    }, []),
    options,
  );

  return {
    inputMatchCount,
    clearSearch,
    goToNextOccurrence,
    goToPrevOccurrence,
    searchInProgress,
    searchText: query,
    onChangeText: onChangeQuery,
  };
}
